import React from "react"

import Layout from "../components/Layout"
import NF404 from "../components/NF404"

const NotFoundPage = () => (
  <Layout bannerBG={true}>
    <NF404 />
  </Layout>
)

export default NotFoundPage
