import React from "react"

import styles from "./styles.module.less"
import arrow from "../../../static/images/whiteArrow.png"

import { Link } from "gatsby"

const NF404 = () => (
  <div className={styles.Error}>
    <div className={styles.colorBg}>
      <div className={styles.errorBg}>
        {/* <div className={styles.title}>
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
      <img className={styles.adjust} src={img404} />
    </div> */}

        <div className={styles.frame}>
          <iframe
            styles="styles.frame"
            name="Framename"
            src="https://jspaint.app"
            //frameborder="0"
            scrolling="auto"
            //class="frame-area"
            width="80%"
            height="700vw"
          ></iframe>
          <div className={styles.bot}>
            <div className={styles.arrow}>
              <img transform="rotateY(180deg)" src={arrow} />
            </div>
            <Link to="/" className={styles.text}>
              GET HOME
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default NF404
